:root {
	/*DEFINITIONS*/
	/* Main brand color: This color should be eye-catching but not harsh. It can be liberally applied to your layout as its main identity. */
	/*Light shades: Use this color as the background for your dark-on-light designs, or the text color of an inverted design.*/
	/*Light accent: Accent colors can be used to bring attention to design elements by contrasting with the rest of the palette.*/
	/*Dark Shades: Use as the text color for dark-on-light designs, or as the background for inverted designs.*/
	/*Dark accent
Another accent color to consider. Not all colors have to be used - sometimes a simple color scheme works best.*/
	--cardBg: #fefefe;
	--bg: #f0f3f6;
	--lightShade: #f4f7f3;
	--lightAccent: #d8dce0;
	--primary: #d53a3a;
	--darkAccent: #b52727;
	--darkShade: #201520;
	--success: #88d53a;
	--successHover: #6eb527;
	--danger: #f44331;
	--warning: #ffbc42;
	--warningHover: #e8ab3c;
	--info: #5da9e9;
	--infoHover: #559ad4;

	/* http://colormind.io/ */
	/* https://www.canva.com/colors/color-wheel/ */
	/* https://coolors.co/ */
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 0.88rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	text-align: left;
	background: var(--bg);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

hr {
	margin: 2vmax;
}

/* ===== UTILITY CLASSES ===== */
/*#region */
.cursor {
	cursor: pointer;
}
.left {
	float: left;
}

.right {
	float: right;
}

.d-none {
	display: none;
}

.d-inline {
	display: inline;
}

/*#endregion*/

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: 500;
	line-height: 1.2;
	color: var(--darkShade);
}
.App {
	text-align: center;
	width: 80%;
	margin: 0 auto;
}

input,
textarea {
	display: block;
	width: 100%;
	height: calc(2.25rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

input:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/*=====BUTTONS=====*/
/*#region*/
button:not(:disabled):not(.disabled),
.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

a,
button {
	outline: none !important;
}

button.disabled,
button:disabled {
	opacity: 0.65;
}

button,
.btn {
	font-size: 0.8rem;
	font-weight: 500;
	color: var(--darkShade);
	display: inline-block;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375rem 0.75rem;
	line-height: 1.5;
	border-radius: 0.25rem;
	margin: 1vh;
}
/*#endregion*/

form {
	width: 60%;
	margin: 2vh auto;
}

label {
	width: 30%;
	padding: 0.325rem 0.875rem;
	margin: 1vh auto;
	display: inline-block;
	margin-bottom: 0.5rem;
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.5;
}
input {
	width: 60%;
	padding: 0.325rem 0.875rem;
	margin: 1vh auto;
}

textarea {
	margin: 2vh auto;
	min-height: 25vh;
	max-height: 70vh;
	overflow-y: auto;
}

.card {
	display: inline-block;
	position: relative;
	width: 100%;
	margin-bottom: 30px;
	border-radius: 6px;
	color: rgba(0, 0, 0, 0.87);
	background: var(--cardBg);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	height: fit-content;
}

.card [class*='content-'] {
	border-radius: 6px;
}
.card .content {
	padding: 15px 30px;
}

ul {
	margin: 2vh 0;
	padding: 2vh 0;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
}

/*=====COLORS=====*/
/*#region*/
.primary {
	background: var(--primary);
	color: var(--lightShade);
	border-color: var(--primary);
}
.primary:hover {
	background: var(--darkAccent);
	color: var(--lightShade);
	border-color: var(--darkAccent);
}

.secondary {
	background: var(--lightAccent);
	color: var(--darkShade);
	border-color: var(--lightAccent);
}
.secondary:hover {
	background: var(--darkShade);
	color: var(--lightAccent);
	border-color: var(--darkShade);
}
/*#endregion*/

.output {
	width: 100%;
	margin: 2vh auto;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.song-details {
	margin-bottom: 10px !important;
}
.words {
	display: inline;
	margin: 0;
	max-width: 35%;
}

.phrases {
	display: inline;
	margin: 0;
	max-width: 62%;
}

/*!#region ========== ACTION BUTTONS ==========*/
.add {
	padding: 0.325rem 0.7rem;
	color: var(--successHover);
	cursor: pointer;
	font-weight: 600;
	position: absolute;
	right: 65px;
	border-radius: 5px;
	vertical-align: middle;
	margin-top: -0.325rem;
}

.add:hover {
	background: var(--successHover);
	color: var(--lightShade);
}

.delete {
	padding: 0.325rem 0.7rem;
	color: var(--darkAccent);
	cursor: pointer;
	position: absolute;
	right: 30px;
	border-radius: 5px;
	vertical-align: middle;
	margin-top: -0.325rem;
}

.delete:hover {
	background: var(--primary);
	color: var(--lightShade);
}
/*#endRegion*/

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

li {
	list-style: none;
	padding: 0.325rem 0;
}
/* @media only screen and (max-width: 990px) {
	textarea {
		width: 80%;
	}
} */

.full-height {
	height: -webkit-fill-available !important;
}
